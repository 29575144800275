import * as React from "react";

import PrimaryButtonWithArrow from "../Buttons/PrimaryButtonWithArrow/PrimaryButtonWithArrow";
import styles from "./PartnersHero.module.scss";

type DataProps = {
  data: Queries.WpPartnersTemplate_Herosinglep;
};

const PartnersHero: React.FC<DataProps> = ({
  data: {
    heroSingleTitle,
    heroSingleSubcopy,
    heroSingleLink,
    heroSingleLinkText,
  },
}) => (
  <div className={`backgroundBlue ${styles.partnersContainer}`}>
    <div className={`full-width shorter`}>
      <div className={`text-alignment ${styles.partnersStyle}`}>
        <h1 data-testid="H1:title" className={styles.bannerTitle}>
          {heroSingleTitle}
        </h1>
        <p
          data-testid="P:subcopy"
          className={styles.bannerSubTitle}
          dangerouslySetInnerHTML={{ __html: heroSingleSubcopy! }}
        />
        <PrimaryButtonWithArrow
          buttonText={heroSingleLinkText!}
          buttonLink={heroSingleLink!}
        />
      </div>
    </div>
  </div>
);

export default PartnersHero;
