import React from "react";

import HomePageSectionsHeader from "../shared/HomePageSectionsHeader";

import styles from "./WhySPSection.module.scss";

export type WhySpCardType = {
  cardHeadline: string;
  cardCopy: string;
  cardImg: {
    altText: string;
    mediaItemUrl: string;
  };
};

type WhySimplePracticeSectionProps = {
  whySpContent: {
    whySpHeadline: string;
    whySpSubcopy: string;
    whySpCards: WhySpCardType[];
  };
  className?: string;
  headerClassName?: string;
};

const WhySimplePracticeSection: React.FC<WhySimplePracticeSectionProps> = ({
  whySpContent,
  className,
  headerClassName,
}) => {
  const { whySpHeadline, whySpSubcopy, whySpCards } = whySpContent;

  const whySpCardsList =
    whySpCards &&
    whySpCards.map(({ cardCopy, cardHeadline, cardImg }, idx) => (
      <article
        data-testid="why-sp-card"
        className={styles.whySpCard}
        key={`${cardHeadline}-${Date.now()}`}
      >
        <figure className={styles.cardImage}>
          <img
            data-testid={`card-img-${idx}`}
            alt={cardImg.altText}
            src={cardImg.mediaItemUrl}
          />
        </figure>

        <h4
          data-testid={`card-headline-${idx}`}
          className={styles.cardHeadline}
        >
          {cardHeadline}
        </h4>
        <p data-testid={`card-copy-${idx}`} className={styles.cardSubcopy}>
          {cardCopy}
        </p>
      </article>
    ));

  return (
    <section className={`${styles.whySpSection} ${className ? className : ""}`}>
      <div className="full-width">
        <HomePageSectionsHeader
          sectionContainerClassNames={`${styles.whySpHeader} ${headerClassName}`}
          sectionHeadline={whySpHeadline}
          sectionSubHeadline={whySpSubcopy}
        />

        <div data-testid="why-sp-cards" className={styles.whySpCards}>
          {whySpCardsList}
        </div>
      </div>
    </section>
  );
};

export default WhySimplePracticeSection;
