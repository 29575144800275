import React from "react";

import styles from "./HomePageSectionsHeader.module.scss";

type TtextAlignOption = "center" | "end" | "start";

type ThomePageSectionsHeader = {
  sectionHeadline: string;
  sectionSubHeadline: string;
  textAlign?: TtextAlignOption;
  sectionContainerClassNames?: string;
  headlineClassNames?: string;
  subHeadlineClassNames?: string;
  withoutHeadline?: boolean;
};

export const HomePageSectionsHeader: React.FC<ThomePageSectionsHeader> = ({
  sectionContainerClassNames,
  sectionHeadline,
  sectionSubHeadline,
  textAlign,
  withoutHeadline,
  headlineClassNames,
  subHeadlineClassNames,
}) => {
  const textAlignOptions = {
    center: styles.textCenter,
    end: styles.textEnd,
  };
  const textAlignStyles = `${
    textAlignOptions[textAlign] ? textAlignOptions[textAlign] : null
  }`;
  const sectionStylesContainer = `${styles.sectionContainer} ${textAlignStyles} ${sectionContainerClassNames}`;
  const headlineStyles = `${styles.sectionHeadline} ${headlineClassNames}`;
  const subHeadlineStyles = `${styles.sectionSubcopy} ${subHeadlineClassNames}`;

  return (
    <header className={sectionStylesContainer}>
      {!withoutHeadline && (
        <h5 data-testid="section-headline" className={headlineStyles}>
          {sectionHeadline}
        </h5>
      )}
      <p
        data-testid="section-subHeadline"
        className={subHeadlineStyles}
        dangerouslySetInnerHTML={{
          __html: sectionSubHeadline,
        }}
      />
    </header>
  );
};

export default HomePageSectionsHeader;
