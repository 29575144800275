import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { IPartnersTemplateData } from "@types";
import styles from "./Partners.module.scss";
import MainLayout from "../../layouts/main";
import TestimonialSliderPContainer from "../../containers/TestimonialSliderPContainer/TestimonialSliderPContainer";
import FeaturesModulePContainer from "../../containers/FeaturesModulePContainer/FeaturesModulePContainer";
import SEO from "gatsby-plugin-wpgraphql-seo";
import WhySimplePracticeSection from "../../components/WhySPSection";
import PricingV2FAQsContainer from "../../containers/PricingV2FAQsContainer/PricingV2FAQsContainer";
import PartnersHero from "../../components/PartnersHero";
import { deIndexSeo } from "../../utils/deIndexSeo";

const PartnersPage: React.FC<PageProps<IPartnersTemplateData>> = ({ data }) => {
  const { wpPage } = data;
  const {
    template: {
      heroSingleP,
      featuresModuleP,
      testimonialSliderP,
      contentFaqsP,
      whySimplepracticeSection,
      globalFooterLargeCtaO,
    },
  } = wpPage;

  return (
    <MainLayout
      FooterLargeCtaData={globalFooterLargeCtaO}
      hasFooterLargeCta={true}
      backgroundColor="blue"
    >
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <PartnersHero
        data={heroSingleP as Queries.WpPartnersTemplate_Herosinglep}
      />
      <FeaturesModulePContainer
        props={featuresModuleP}
        page={featuresModuleP.featuresModuleTitle}
        withPaddingB={false}
        isPartners
      />
      <WhySimplePracticeSection
        whySpContent={{ ...whySimplepracticeSection }}
        className={styles.partnerContainerSection}
        headerClassName={styles.partnerContainerHeaderSection}
      />
      <TestimonialSliderPContainer
        props={testimonialSliderP}
        hasCustomerSatisfaction={true}
      />
      <PricingV2FAQsContainer
        contentFaqsP={contentFaqsP}
        className={styles.faqsContainer}
      />
    </MainLayout>
  );
};

export default PartnersPage;

export const query = graphql`
  query PartnersTemplateQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...GetPartnersTemplateInfo
    }
  }
`;
